import './App.css';
import {
    Alert,
    AlertTitle,
    AppBar,
    Container,
    createTheme,
    Link, List, ListItem, ListItemIcon, ListItemText,
    Paper,
    ThemeProvider,
    Toolbar,
} from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import * as PropTypes from "prop-types";
import React from "react";
import logo from './Gartner_logo_white.png'

function Item(props) {
    return null;
}

Item.propTypes = {children: PropTypes.node};

const theme = createTheme({
    palette: {
        primary: {
            main: "#002856"
        },
        secondary: {
            main: "#0052D6"
        }
    }
})

function App() {
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <AppBar position="static">
                    <Toolbar variant="dense" style={{paddingLeft: "0px"}}>
                        <img src={logo} alt="Gartner" width="100" style={{padding: "20px"}}/>
                    </Toolbar>
                </AppBar>
                <Container component="main" sx={{ mb: 4 }} style={{maxWidth: "750px"}}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                        <Alert severity="error">
                            <AlertTitle><strong>Gartner is moving from Oracle Java to Open JDK!</strong></AlertTitle>
                            <br/>
                            Please download a long-term support release (version 11 or 17) from the site below:
                            <List dense>
                                <ListItem>
                                    <ListItemIcon style={{minWidth: "2em"}}><LinkIcon fontSize="small"/></ListItemIcon>
                                    <Link target="_blank" href="https://adoptium.net" underline="hover" variant="body2" color="secondary">https://adoptium.net</Link>
                                </ListItem>
                            </List>
                            <br/>
                            <strong>Note:</strong> You will need to determine the best version for your needs.
                            <br/><br/>
                            If you have any technical issues or require assistance, please <Link target="_blank" href="https://gartnerprod.service-now.com/sp" underline="hover" variant="body2" color="secondary">call or chat with the Help Desk</Link>.
                        </Alert>
                    </Paper>
                </Container>
            </div>
        </ThemeProvider>
    );
}

export default App;
